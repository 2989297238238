import React, { useState } from "react"
import styled from "@emotion/styled"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { callToActionStyle } from "../styles"

const Form = styled.form`
  margin: 0 auto;
  display: block;
  text-align: center;
  formset {
    display: block;
    input {
      padding: 0.3rem 1rem;
      max-width: 400px;
      margin-bottom: 0.3rem;
    }
  }
`

const Header = styled.div`
  text-align: center;
  margin-top: 8rem;
  h2 {
    font: 400 48px ${props => props.theme.fonts.serif};
  }
  h3 {
    font: 400 30px ${props => props.theme.fonts.sans};
  }
`

const LoginPage = () => {
  const [error, setError] = useState(false)

  const onSubmit = e => {
    e.preventDefault()
    setTimeout(function() {
      setError(true)
    }, 2000)
  }
  return (
    <Layout>
      <SEO title="Investor Login" />
      <Header>
        <h2>Welcome back</h2>
        <h3>Please login to access our investor's dashboard.</h3>
      </Header>
      <Form onSubmit={onSubmit}>
        {error && (
          <small style={{ color: "red", margin: "0.4rem 0", display: "block" }}>
            Invalid credentials, please try again.
          </small>
        )}
        <formset>
          <input placeholder="Username" id="username" type="text" />
        </formset>
        <formset>
          <input placeholder="Password" id="password" type="password" />
        </formset>
        <button css={theme => callToActionStyle(theme)} type="submit">
          Login
        </button>
      </Form>
    </Layout>
  )
}

export default LoginPage
